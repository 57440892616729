@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "InterTight";
  src: url(./assets/fonts/InterTight-Regular.ttf);
}

@font-face {
  font-family: "InterTightBold";
  src: url(./assets/fonts/InterTight-Bold.ttf);
}

@font-face {
  font-family: "HelveticaNowDisplay";
  src: url(./assets/fonts/HelveticaNowDisplay-Regular.ttf);
}

@font-face {
  font-family: "PlusJakartaSans";
  src: url(./assets/fonts/PlusJakartaSans-VariableFont_wght.ttf);
}
